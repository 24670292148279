import * as React from 'react';
import './DealList.css';
import './Share.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

//Dialog
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import InfiniteScroll from "react-infinite-scroller";
import { RotatingLines } from 'react-loader-spinner'
import { useTranslation } from "react-i18next";
import i18n from "i18next";      
import { sendData } from '../functions/Utils.js'   


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DealList(props) {
  let dealInit ={ }
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(window.location.hash === "#open");
  const [deal, setDeal] = React.useState(dealInit);
  const [open2, setOpen2] = React.useState(window.location.hash === "#open2" );
  const [copied, setCopied] = React.useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Deal');
  const isFacebookInAppBrowser = navigator.userAgent.match(/FBAN|FBAV|FB_IAB/i);

  function setHash(hash) {
    if(hash.indexOf('#') === -1)
      hash = '#' + hash
    if(document.location.hash) {
      document.location.hash = hash
      return
    }
    if(window.location.hash) {
      window.location.hash = hash
      return
    }
    if(document.location.href) {
      document.location.href = hash
      return
    }
    window.location.href = hash
  }


  
  React.useEffect(() => {
    const onHashChange = () => setOpen(window.location.hash === "#open");
    const onHashChange2 = () => setOpen2(window.location.hash === "#open2");
    window.addEventListener("hashchange", onHashChange);
    window.addEventListener("hashchange", onHashChange2);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
      window.removeEventListener("hashchange", onHashChange2)
    }
  }, [])

  const handleClickOpen = (deal) => {
    setDeal(deal);
    setHash("#open");
    gaEventTracker('ClickDetail', '', deal.id);
    return false;
  };

  const handleClickOpen2 = (deal) => {
    setDeal(deal);
    setCopied(false);
    setHash("#open2");
    return false;
  };

  const handleClose = () => {
    window.history.back();
    if(!isFacebookInAppBrowser)
    {
      setCopied(false);
    }
  };
  const loadMore = (page) =>
  {
    if(!props.isLoading) {
      props.getDeals(page);
    }
    else
    {
      if(props.isRefScroller.current.pageLoaded !== null)
        props.isRefScroller.current.pageLoaded = page-1;
    }
  }

  return (
    <React.Fragment>
    <InfiniteScroll
    id="DealListWrapper"
    ref={props.isRefScroller}
    pageStart={0}
    loadMore={loadMore}
    hasMore={props.hasMore}
    loader={
      <RotatingLines
        visible={props.inListLoadShow}
        height="75"
        width="75"
        strokeColor="#a6966d"
        strokeWidth="4"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{ }}
        wrapperClass=""
        />
    } 
  >
    <List id="DealList" className="DealList" sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {
        props.deals?.map(deal =>

              <ListItem 
                key={deal.id}
                id={"dealId" + deal.id}
                disableGutters 
                alignItems="flex-start" 
                className={"borderNoBottom" + (deal.isHighlight ? ' highlight' : '')}
                secondaryAction={
                    <IconButton aria-label="" >
                      {deal.isHighlight ? <StarBorderIcon /> : <ArrowForwardIosSharpIcon />}

                    </IconButton>
                  }
                  onClick={(e) => {
                    if(isFacebookInAppBrowser){
                      sessionStorage.setItem('dealId', deal.id);
                      sessionStorage.setItem('selected', JSON.stringify(props.selectedValue));   
                      sessionStorage.setItem('sort', props.sortValue);
                      sessionStorage.setItem('search', props.searchValue);   
                    }
                    if(!deal.promoCode)
                    {
                      window.open(deal.dealUrl, '_blank');
                      sendData(deal.id ,'Click');  
                      gaEventTracker('Click', '', deal.id);
                      return false;
                    }
                    e.preventDefault();
                    e.stopPropagation();  
                    handleClickOpen2(deal); 
                    return false;}}
                  >
                <ListItemAvatar>
                  <Avatar alt={i18n.language === "fr" ? deal.titleFr : deal.titleEn}  variant="square" sx={{ width:'96px', height: '96px', objectFit: 'contain'}} src={deal.imageUrl ? deal.imageUrl : '/EmptyImage.jpg'} />
                  <span className='bandeDore'>{deal.totalDiscountPCT > 0 ? (deal.totalDiscountPCT + '% ' + t("Deal_Card_Total_Discount")) : t("Deal_Card_Sale") } </span>
                </ListItemAvatar>
                <ListItemText sx={{ marginLeft: '5px', marginTop: '0'}}
                  primary={i18n.language === "fr" ? deal.titleFr : deal.titleEn}
                  secondary={
                    <React.Fragment>
                          {deal.priceDropBool ? <span>📉 {t("Deal_Card_Price_Drop")} ~{deal.priceDrop}$<br/></span>: null}
                          {deal.discount !== 0 && deal.isLightning ? <span>⚡️ {t("Deal_Card_Lightning_deal", { "0":  deal.discount})} {deal.discount}%<br/></span>: null}
                          {deal.discount !== 0 && !deal.isLightning  && !deal.isPrimeExclusive ? <span>🏷 {t("Deal_Card_Discount")} {deal.discount}%<br/></span>: null}
                          {deal.discount !== 0 && deal.isPrimeExclusive ? <span>🟦 {t("Deal_Card_Prime_Exclusive")} {deal.discount}%<br/></span>: null}
                          {deal.promoCode ? <span>✍️ {t("Deal_Card_Promo_Code")} {deal.promoCodeDiscount}{deal.promoCodeType}<br/></span>: null}
                          {deal.couponBool ? <span>🎫 {t("Deal_Card_Coupon")} {deal.coupon}{deal.couponType}<br/></span> : null} 
                          {deal.checkoutDiscountBool ? <span>🛒 {t("Deal_Card_Checkout_Discount")} {deal.checkoutDiscount}{deal.checkoutType}<br/></span>: null}
                         {(deal.priceDropBool || deal.discount !== 0 || deal.promoCode ||  deal.couponBool || deal.checkoutDiscountBool) && <Button className='button-how'   onClick={(e) => {e.preventDefault(); e.stopPropagation();  handleClickOpen(deal); return false;}}>{t("Deal_Card_How")}</Button>}
                    </React.Fragment>
                  } />
             
            </ListItem>
          )
      }
    </List>
  
    </InfiniteScroll>
    <Dialog
      className='DialogBox'
        sx={{ m: 0, p: 0 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}  className='titleColor1'>{t("How_Popup_Title")}</DialogTitle>
        <IconButton
          aria-label="fermer"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right:4,
            top: 4,
            color: '#0d1b36',
          }}
        >
          <CloseIcon />
        </IconButton>  
        <DialogContent className='DialogBoxContent'>  
          <DialogContentText id="detailDialog" >
            <div>
              {deal.priceDropBool && <div>
                <h2 className='titleColor1'>📉 {t("How_Price_Drop_Title", { "0":  deal.priceDrop + "$"})}</h2>
                <span>
                {t("How_Price_Drop_Description")}
                </span>  
              </div>}
              {deal.discount !== 0 && deal.isLightning  &&  <div>
                <h2 className='titleColor1'>{deal.priceDropBool ? <span>+<br/></span> : null}⚡️ {t("How_Lightning_Deal_Title", { "0":  deal.discount})}</h2>
                <span>
                {t("How_Lightning_Deal_Description")}
                </span>  
              </div>}
              {deal.discount !== 0 && !deal.isLightning  && !deal.isPrimeExclusive && <div>
                <h2 className='titleColor1'>{deal.priceDropBool ? <span>+<br/></span> : null}🏷 {t("How_Discount_Title")} ({deal.discount}%)</h2>
                <span>
                {t("How_Discount_Description")}
                </span>  
              </div>}
              {deal.discount !== 0 && deal.isPrimeExclusive && <div>
                <h2 className='titleColor1'>{deal.priceDropBool && <span>+<br/></span>}🟦 {t("How_Prime_Exclusive_Title")} ({deal.discount}%)</h2>
                <span>
                {t("How_Prime_Exclusive_Description")}
                </span>  
              </div>}
              {deal.promoCode && <div>
                <h2 className='titleColor1'>{(deal.priceDropBool || deal.discount !== 0) && <span>+<br/></span>}✍️ {t("How_Promo_Code_Title")} ({deal.promoCodeDiscount}{deal.promoCodeType})</h2>
                <span>
                  {t("How_Promo_Code_Description")}
                </span>  
              </div>}
              {deal.couponBool && <div>
                <h2 className='titleColor1'>{(deal.priceDropBool || deal.discount !== 0 || deal.promoCode) && <span>+<br/></span> }🎫 {t("How_Coupon_Title")} ({deal.coupon}{deal.couponType})</h2>
                <span>{t("How_Coupon_Description")}</span>
              </div>}
              {deal.checkoutDiscountBool && <div>
                <h2 className='titleColor1'>{(deal.priceDropBool || deal.discount !== 0 || deal.promoCode ||  deal.couponBool) && <span>+<br/></span>}🛒 {t("How_Checkout_Discount_Title")} ({deal.checkoutDiscount}{deal.checkoutType})</h2>
                <span>
                {t("How_Checkout_Discount_Description")}
                </span>  
              </div>}
              {deal.totalDiscountPCT > 0 && <div>
                <h2 className='titleColor1'>=<br/><span className='bandeDore2'>{t("How_Popup_Total")} {deal.totalDiscountPCT}%</span></h2>
              </div>}
            </div>
            <br/>
            <Button className='button-color1' onClick={handleClose}>{t("How_Popup_Button")}</Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
            className='DialogBox titleColor1'
            sx={{ m: 0, p: 0 }}
            open={open2}
            onClose={handleClose}
            TransitionComponent={Transition}>
                  <DialogTitle sx={{ m: 0, p: 2, fontSize: "35px" }} >{t("Promocode_Popup_Title")}</DialogTitle>
        <IconButton
          aria-label="fermer"
          onClick={handleClose}
          size="large"
          sx={{
            position: 'absolute',
            right:0,
            top: 12, 
            color: '#0d1b36',
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>  
        <DialogContent className='DialogBoxContent'>  
          <DialogContentText id="detailDialog">
            <div>
            <span>{t("Promocode_Popup_Content")}</span>
            <br/>  <br/>
            <button className={copied ? "codePromo copied" : "codePromo"}
            onClick={() => {setCopied(true);  navigator.clipboard.writeText(deal.promoCode);}}>
              {deal.promoCode}<br/>
              {copied && <span>{t("Promocode_Popup_Clicked_Code_Msg")}</span>}
            </button>
              <br/>
               <span className='codePromoUnder'>{t("Promocode_Popup_Click_Code_Msg")}</span> 
            </div>
            <br/>
           
              <Button className='button-color1' onClick={(e) => {
                handleClose();
                window.open(deal.dealUrl, '_blank');
                sendData(deal.id ,'Click');  
                gaEventTracker('Click', '', deal.id);
                return false;
              }}>{t("Promocode_Popup_Button")}</Button>
         
          </DialogContentText>
        </DialogContent>
      </Dialog>    
    </React.Fragment>
  );
}
